import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { getData, getFirst, isValid } from 'clients/Clients';
import CustomerClient, { getUserLogGuide, postUserLogGuide } from 'clients/CustomerClient';
import { getProductGroupsClient } from 'clients/ProductClientV2';
import PromoClient from 'clients/PromoClient';
import VideoClient from 'clients/VideoClient';
import WebServiceClient from 'clients/WebServiceClient';
import BannerSliderNew from 'components-v2/mocules/BannerSliderNew';
import DrugGroup from 'components-v2/mocules/DrugGroup';
import ModalRating from 'components-v2/mocules/ModalRating';
import NanoBanner from 'components-v2/mocules/NanoBanner';
import ModalLayout from 'components-v2/mocules/OcrFlowContainer/Components/ModalLayout';
import { ConfirmRemind } from 'components-v2/mocules/OcrFlowContainer/ConfirmContent/ConfirmRemind';
import Template from 'components/layout/Template';
import SliderProductBlock from 'components/mocules/Skeleton/SliderProductBlock';
import HomePageBlock from 'components/organisms/HomePageBlock';
import HomePageBlockV2 from 'components/organisms/HomePageBlockV2';
import MiniBannerBlock from 'components/organisms/MiniBannerBlock';
import { GUIDE_FEATURE } from 'constants/data/guideline';
import { INSIDER_RECOMMENDATION } from 'constants/Enums';
import { HOME_ICON_MEGA_SALE } from 'constants/Images';
import { LIKE_ICON, NEW_PRODUCT } from 'constants/Images/mobile';
import { PROMO_CODES } from 'constants/Paths';
import { EnumBlockCodeTracking, EnumTrackingStatic } from "constants/tracking";
import { useAuth } from 'context/Auth';
import { usePopup } from 'context/Popup';
import useInsiderPopup from 'hooks/useInsiderPopup';
import useOpenOnDateChange from 'hooks/useOpenOnDateChange';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { SHOW_INSIDER_RECOMMEND, TAG_NEW } from 'sysconfig';
import { calculateTwoDates } from 'utils/helper';
import ImageFallback from 'utils/ImageFallback';
import LiveChat from 'utils/LiveChat';
import MonitorUtils, { mapScreenToEnum, MONITORING_COLLECTOR_TYPE } from 'utils/MonitorUtils';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import useGetTagPromotion from 'zustand-lib/useGetTagPromotion';
import styles from './HomeUser.module.css';

// const ProductSliderSection = dynamic(() => import('components/organisms/ProductSliderSection'));
const Partners = dynamic(() => import('components-v2/organisms/pages/home/Partners'), { ssr: false });
const CustomerSayAbout = dynamic(() => import('components-v2/organisms/pages/home/CustomerSayAbout'), { ssr: false });
const LiveStream = dynamic(() => import('components-v2/mocules/LiveStream'), { ssr: false });
const PopupTourRSLoyaltyPoint = dynamic(() => import('components/mocules/PopupGuideRSLoyaltyPoint'), { ssr: false });
const HomeProductRecommendationBlock = dynamic(() => import('components-v2/organisms/pages/home/HomeProductRecommendationBlock'), { ssr: false });

const ImageIconGoodPrice = () => (
  <ImageFallback src={`${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/iconV2/medical_drug.png`} width={40} height={40} />
);

const INIT_LIVE = {
  isLive: false,
  url: '',
  liveStreamID: null,
  chatVisible: true,
  hiddenLive: false,
  roomID: '',
  startTimeShifting: '',
};
const SIZE_ICON = 40;
const SIZE_ICON_MOBILE = 24;

function PCScreen({
  isMobile = false,
  bannerStatus = null,
  products = [],
  loading,
  categories = [],
  dataShowBoxYir,
  atLeastLoyaltyPoint,
  loyaltyRSPoint,
  isPopupRSLoyaltyPoints,
  togglePopupRSLoyaltyPoints,
  isOnMovingPromoCode = false,
  toggleMovingPromoCode,
  user,
  isShowNextPopup = false,
}) {
  const router = useRouter();
  const [productData, setProductData] = useState({
    RENHAT: [],
    THUOCKEDONGIATOT: [],
    BANCHAY: [],
    NEW: [],
    DEAL: [],
  });
  const [openRating, setOpenRating] = useState();
  const [mediaLive, setMediaLive] = useState(INIT_LIVE);
  const mediaLiveRef = useRef(INIT_LIVE);
  const appSetting = useStore((state) => state.appSetting);

  const megaBlockConfigTitle = appSetting?.HOME_PRODUCTS_BLOCK_CONFIG?.RE_NHAT?.title

  let timeIntervalMediaLive = null;

  useEffect(() => {
    const productDataMap = new Map();
    products.forEach(({ tags, productDatas }) => {
      tags?.forEach((tag) => {
        const currentSet = productDataMap.get(tag) || new Set();
        productDatas?.forEach((product) => currentSet.add(product));
        productDataMap.set(tag, currentSet);
      });
    });

    productDataMap.forEach((productSet, tag) => {
      productDataMap.set(tag, Array.from(productSet));
    });

    setProductData((prevProductData) => ({
      ...prevProductData,
      RENHAT: [...(productDataMap.get('RENHAT') ?? [])],
      THUOCKEDONGIATOT: [...(productDataMap.get('GY7G') ?? [])],
      BANCHAY: [...(productDataMap.get('BANCHAY') ?? [])],
      NEW: [...(productDataMap.get('V2U1') ?? [])],
      DEAL: [...(products[4]?.productDatas ?? [])],
    }));
  }, [products]);

  useEffect(() => {
    const timeRatingLocal = localStorage.getItem('time-rating');
    if (timeRatingLocal) {
      const calculateDateShowRating = calculateTwoDates(timeRatingLocal);
      const timeHiddenRating = user?.customerRatingConfig?.durationGapMinutes ? user?.customerRatingConfig?.durationGapMinutes / 60 / 24 : 1;
      if (calculateDateShowRating > timeHiddenRating && user?.customerRatingConfig?.showRatingPopup) {
        setOpenRating(true);
      }
    } else {
      setOpenRating(user?.customerRatingConfig?.showRatingPopup);
    }
  }, []);

  const setMediaState = (data) => {
    const mediaHistory = mediaLiveRef.current;

    const stateData = {
      isLive: mediaHistory.isLive,
      url: mediaHistory?.url,
      roomID: mediaHistory?.roomID,
      liveStreamID: mediaHistory?.liveStreamID,
      startTimeShifting: mediaHistory?.startTimeShifting,
    };

    const compareData = {
      isLive: data.isLive,
      url: data?.url,
      roomID: data?.roomID,
      liveStreamID: data?.liveStreamID,
      startTimeShifting: data?.startTimeShifting,
    };

    if (JSON.stringify(stateData) !== JSON.stringify(compareData)) {
      mediaLiveRef.current = compareData;
      setMediaLive((curr) => ({ ...curr, ...data }));
    }
  };

  const onLiveListener = async () => {
    const result = await VideoClient.getCurrentLiveStream();
    if (!isValid(result)) {
      setMediaState(INIT_LIVE);
      return;
    }
    const data = getData(result);
    if (data?.length > 0) {
      const response = data[0];
      if (mediaLiveRef.current.liveStreamID === response?.liveStreamID || !mediaLiveRef.current.liveStreamID) {
        const mediaLiveData = {
          isLive: true,
          url: response?.hlsUrl,
          roomID: response?.roomID,
          liveStreamID: response?.liveStreamID,
          startTimeShifting: response?.startTimeShifting,
        };

        setMediaState(mediaLiveData);
        const metaData = {
          liveStreamID: response?.liveStreamID,
        };
        MonitorUtils.sendLivestreamEvent(MONITORING_COLLECTOR_TYPE.DISPLAY_LIVESTREAM, metaData);
      }
    } else {
      setMediaState(INIT_LIVE);
    }
  };

  const getCurrentViewLiveStream = async () => {
    const liveStreamID = mediaLiveRef.current?.liveStreamID;
    if (!liveStreamID) return 0;

    const result = await VideoClient.getViewCurrentLive({ liveStreamID });
    const currentView = result.data?.[0]?.currentView;
    if (currentView) {
      return currentView;
    }
    return 0;
  };

  const onView = async () => {
    const liveStreamID = mediaLiveRef.current?.liveStreamID;
    if (!liveStreamID) return;
    await VideoClient.reportView({ liveStreamID });
  };

  useEffect(() => {
    onLiveListener();
    timeIntervalMediaLive = setInterval(() => {
      onLiveListener();
    }, 60000);
    return () => {
      clearInterval(timeIntervalMediaLive);
    };
  }, []);

  // tracking monitor
  const trackingMonitorClientClickChangingPointGuide = async () => {
    const event = MONITORING_COLLECTOR_TYPE.CLICK_CHANGING_POINT_GUIDE;
    const metaData = {
      customerId: String(user?.customerID),
      source: 'web-desktop',
      screen: mapScreenToEnum({}, router.pathname),
      description: `Click nút “Đổi điểm” trong popup guide điểm tích lũy`,
    };
    MonitorUtils.rsLoyaltyPointClickEvent(event, metaData);
  };

  const handleConfirmRSLoyaltyPoint = async ({ isConfirm = false }) => {
    const feature = GUIDE_FEATURE.NOTI_RESET_POINT;
    await postUserLogGuide({ feature, isViewAll: isConfirm });

    if (isConfirm) {
      toggleMovingPromoCode(true);
      trackingMonitorClientClickChangingPointGuide();
      router.push({
        pathname: PROMO_CODES,
        query: { isScroll: true },
      });
    } else {
      togglePopupRSLoyaltyPoints(false);
    }
  };

  return (
    <>
      {mediaLive.isLive && mediaLive.url && mediaLive.liveStreamID && (
        <Grid container className={styles.boxChatContainer}>
          <LiveStream
            onCount={getCurrentViewLiveStream}
            onView={onView}
            urlPull={mediaLive.url}
            chat={<LiveChat liveStreamID={mediaLive?.liveStreamID} />}
            startTime={mediaLive.startTimeShifting}
          />
        </Grid>
      )}
      <div className={styles.containerBanner}>
        <Grid container className={styles.boxContainer}>
          <Grid item xs={12} sm={7}>
            {bannerStatus === 'ON' && <BannerSliderNew isMobile={isMobile} />}
          </Grid>
          <Grid item xs={12} sm={5}>
            <NanoBanner dataShowBoxYir={dataShowBoxYir} />
          </Grid>
        </Grid>
      </div>

      <div className="SliderProductWrap">
        {loading && (
          <>
            <SliderProductBlock />
            <SliderProductBlock />
            <SliderProductBlock />
            <SliderProductBlock />
          </>
        )}

        {productData.RENHAT?.length > 0 && (
          <HomePageBlockV2
            name={megaBlockConfigTitle}
			blockCodeTracking={EnumBlockCodeTracking.HOME_MEGA_BLOCK.replace("<blockName>", megaBlockConfigTitle || EnumTrackingStatic.MEGA_BLOCK)}
			viewMore
            redirectUrl={appSetting?.HOME_PRODUCTS_BLOCK_CONFIG?.RE_NHAT?.url || '/products'}
            type="MEGA-SALE"
            icon={<ImageFallback src={HOME_ICON_MEGA_SALE} width={40} height={40} />}
            product={productData.RENHAT}
            isMobile={isMobile}
            gap={'15px'}
            backgroundImage={`${appSetting?.HOME_PRODUCTS_BLOCK_CONFIG?.RE_NHAT?.backgroundImageDesktop || "/images/home/BG2.png"}`}
          />
        )}

        {categories.isLoaing ? <SliderProductBlock /> : <DrugGroup isMobile={isMobile} categories={categories} />}

        {productData.THUOCKEDONGIATOT?.length > 0 && (
          <HomePageBlockV2
            name="Thuốc kê đơn giá tốt"
			blockCodeTracking={EnumBlockCodeTracking.HOME_KE_DON_GIA_TOT}
			viewMore
            redirectUrl="/products?currentTab=thuoc-ke-don"
            product={productData.THUOCKEDONGIATOT}
            icon={<ImageIconGoodPrice />}
            isMobile={isMobile}
          />
        )}

        <MiniBannerBlock isMobile={isMobile} />
        {productData.BANCHAY?.length > 0 && (
          <HomePageBlockV2
            name="Sản phẩm bán chạy"
			blockCodeTracking={EnumBlockCodeTracking.HOME_BEST_SELLER_PRODUCTS}
			viewMore
            redirectUrl="/products?currentTab=san-pham-ban-chay"
            type="BANCHAY"
            product={productData.BANCHAY}
            icon={<ImageFallback src={LIKE_ICON} width={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} height={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} />}
            isMobile={isMobile}
          />
        )}

        {productData.DEAL?.length > 0 && (
          <HomePageBlockV2
            name="Sản phẩm khuyến mãi"
			blockCodeTracking={EnumBlockCodeTracking.HOME_PROMOTION_PRODUCTS}
			icon={
              <ImageFallback
                src={`${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/iconV2/khuyenmai.svg`}
                width={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON}
                height={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON}
              />
            }
            redirectUrl="/khuyenmai"
            isMobile={isMobile}
            product={productData.DEAL}
          />
        )}

        {productData.NEW?.length > 0 && (
          <HomePageBlockV2
            name="Sản phẩm mới"
            icon={
              <ImageFallback src={NEW_PRODUCT} width={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} height={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} />
            }
            type={TAG_NEW}
            redirectUrl="/products?currentTab=san-pham-moi&tag=V2U1&page=1&sort=NEWEST"
            isMobile={isMobile}
            product={productData.NEW}
            viewMoreInTitle={false}
            forceShowButtonAtBottom
            blockCodeTracking={EnumBlockCodeTracking.HOME_NEW_PRODUCTS}
          />
        )}

        {SHOW_INSIDER_RECOMMEND && <HomePageBlock name="Sản phẩm gợi ý" viewMore type={INSIDER_RECOMMENDATION.MOST_VIEWED} redirectUrl="/products" blockCodeTracking={EnumBlockCodeTracking.HOME_RCM} />}

        <HomeProductRecommendationBlock viewMoreInTitle={false} showBtnAllAtBottom blockCodeTracking={EnumBlockCodeTracking.HOME_JFY} />

        {!isPopupRSLoyaltyPoints && user?.customerRatingConfig && isShowNextPopup ? (
          <ModalRating open={openRating} onClose={() => setOpenRating(false)} configData={user} />
        ) : null}
      </div>

      {loyaltyRSPoint > 0 && isPopupRSLoyaltyPoints ? (
        <PopupTourRSLoyaltyPoint
          open={isPopupRSLoyaltyPoints}
          handleConfirm={() => handleConfirmRSLoyaltyPoint({ isConfirm: true })}
          handleClose={() => handleConfirmRSLoyaltyPoint({ isConfirm: false })}
          loyaltyRsPoint={loyaltyRSPoint}
          isMoving={isOnMovingPromoCode}
          atLeastLoyaltyPoint={atLeastLoyaltyPoint}
        />
      ) : null}

      <Partners isLogin />
      <CustomerSayAbout isLogin />
    </>
  );
}

export default function HomeUser({ pageName, pageTitle, bannerStatus, isMobile, insiderUrl, projectId }) {
  const { user } = useAuth();
  const [isOpen, handleClose] = useOpenOnDateChange(user?.isRequiredCertificates, true);

  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const toggleFeature = useStore((state) => state.toggleFeature);
  const toggleBeta = useMobileV2((state) => state.toggleBeta);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState({ data: [], isLoading: false });
  const [dataYir, setDataYir] = useState();
  const [isShowGuide, setIsShowGuide] = useState(false);
  const [dataShowBoxYir, setDataShowBoxYir] = useState();
  // res loyalty point
  const [rsLoyaltyPoint, setRsLoyaltyPoint] = useState();
  const [atLeastLoyaltyPoint, setAtLeastLoyaltyPoint] = useState(0);
  const [isPopupRSLoyaltyPoints, togglePopupRSLoyaltyPoints] = useState(true); // set true for first priority guide
  const [isOnMovingPromoCode, toggleMovingPromoCode] = useState(false);
  // handle check remind popup if user chưa có giấy phép
  const { setDisplayPopup, isShowNextPopup } = useInsiderPopup(user, insiderUrl, projectId);
  const { canShowNextPopup } = usePopup();

  const router = useRouter();
  const {
    query: { trial = false, chatTs },
  } = router || {};

  useEffect(() => {
    if (!isMobileV2 && trial) {
      toggleBeta();
    }
  }, [trial]);

  useEffect(() => {
    // Don't display insider popup when there is already a popup opened
    if (!canShowNextPopup) {
      return setDisplayPopup(false);
    }
    const lastOpenRemindDate = localStorage?.getItem('lastOpenRemindDate');
    const keyToggleOcrPopup = localStorage?.getItem('keyToggleOcrPopup');
    if (user?.isRequiredCertificates && keyToggleOcrPopup === 'true') {
      const dateToCompare = lastOpenRemindDate;
      const todayString = new Date().toISOString().split('T')[0];
      if (dateToCompare === todayString) {
        return setDisplayPopup(true);
      }
    } else {
      localStorage.setItem('keyToggleOcrPopup', 'true');
    }

    if (!user?.isRequiredCertificates) {
      return setDisplayPopup(true);
    }
    return null;
  }, [user, canShowNextPopup]);

  useEffect(() => {
    if (isShowNextPopup) {
      setIsShowGuide(true);
    }
  }, [isShowNextPopup]);

  // promotion gift tag, call one place on time use anywhere
  const { getPromoLists } = useGetTagPromotion();
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getPromoLists({ getVoucherInfo: false, signal });

    return () => controller.abort();
  }, []);

  const fetchDataCategories = async () => {
    setCategories((previousState) => ({ ...previousState, isLoading: true }));
    const categoriesRes = await WebServiceClient.getCategories({});

    if (!isValid(categoriesRes)) {
      setCategories({ data: [], isLoading: false });
      return;
    }

    const data = getData(categoriesRes);
    setCategories({ data, isLoading: false });
  };

  useEffect(() => {
    (async () => {
      fetchDataCategories();
      setLoading(true);
      const productsRes = await getProductGroupsClient();

      if (!isValid(productsRes)) {
        setLoading(false);
        return;
      }

      const data = getData(productsRes);
      setProducts(data);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (chatTs != null) toggleFeature('chatTs', chatTs, { isCookie: true });
  }, [chatTs]);

  useEffect(async () => {
    const { query } = router;
    if (query?.yearreview) {
      const getYearInReview = await WebServiceClient.getYearInReview({});
      if (isValid(getYearInReview)) {
        setDataYir(getYearInReview);
        setIsShowGuide(false);
      } else {
        setDataShowBoxYir({ campaignBoxId: getYearInReview?.data?.[0]?.webMobileCampaignBoxID || '' });
        localStorage.setItem(
          'finishYir',
          JSON.stringify({
            isFinish: true,
            notFound: true,
            customerID: user?.customerID,
            campaignBoxId: getYearInReview?.data?.[0]?.webMobileCampaignBoxID,
          }),
        );
      }
    }
  }, [router]);

  // guide reset Loyalty point
  useEffect(() => {
    (async () => {
      const [respListLoyalty, respRsLoyaltyPoint, respCheckGuideRSPoint] = await Promise.all([
        PromoClient.getSelfLoyaltyList({}),
        CustomerClient.getUserResetLoyaltyPoint({}),
        getUserLogGuide({
          q: {
            feature: GUIDE_FEATURE.NOTI_RESET_POINT,
          },
        }),
      ]);

      // get rs loyalty point
      if (respRsLoyaltyPoint?.status === 'OK') {
        setRsLoyaltyPoint(getFirst(respRsLoyaltyPoint) || 0);
      } else {
        setRsLoyaltyPoint(0);
      }
      // get minimum loyalty point on list
      if (respListLoyalty?.status === 'OK') {
        const atLeastLoyaltyPoint = Math.min.apply(
          0,
          getData(respListLoyalty).map((item) => item?.point),
        );

        setAtLeastLoyaltyPoint(atLeastLoyaltyPoint || 0);
      } else {
        setAtLeastLoyaltyPoint(0);
      }

      const dataCheckGuide = getFirst(respCheckGuideRSPoint);
      const condRsLoyaltyPoint = respRsLoyaltyPoint && getFirst(respRsLoyaltyPoint) > 0;
      if (dataCheckGuide?.isActive && !dataCheckGuide?.isGuided && condRsLoyaltyPoint) {
        togglePopupRSLoyaltyPoints(true);
      } else {
        togglePopupRSLoyaltyPoints(false);
      }
    })();
  }, []);

  return (
    <Template isMobile={isMobile} pageName={pageName} pageTitle={pageTitle} showTopSearchMV2 isWelcomeBack={user?.isWelcomeBack}>
      <PCScreen
        categories={categories}
        isMobile={isMobile}
        bannerStatus={bannerStatus}
        provinceCode={user?.provinceCode}
        products={products}
        loading={loading}
        dataYir={dataYir}
        isShowGuide={isShowGuide}
        dataShowBoxYir={dataShowBoxYir}
        atLeastLoyaltyPoint={atLeastLoyaltyPoint}
        loyaltyRSPoint={rsLoyaltyPoint} // reset loyalty point on this year
        isPopupRSLoyaltyPoints={isPopupRSLoyaltyPoints}
        togglePopupRSLoyaltyPoints={togglePopupRSLoyaltyPoints}
        isOnMovingPromoCode={isOnMovingPromoCode}
        toggleMovingPromoCode={toggleMovingPromoCode}
        user={user}
        isShowNextPopup={isShowNextPopup}
      />
      <ModalLayout visible={isOpen} onClose={handleClose}>
        <FormControl fullWidth>
          <ConfirmRemind onClose={handleClose} />
        </FormControl>
      </ModalLayout>
    </Template>
  );
}

